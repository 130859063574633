// styles
import "./Navbar.css";
import {Link} from "react-router-dom";

import {Typography, Grid, Button, Box} from "@mui/material";
import * as React from "react";

const MenuItem = (props) => {
  return (
    <Box sx={{marginLeft: "20px", marginRight: "20px"}}>
      {props.children}
    </Box>
  );
};

export default function Navbar({role, signOut}) {
  const appType = process.env.REACT_APP_APPTYPE;
  return (
    <div className="container">
      <nav>
        <div className="logo">
          <Link to="/">
            {/*<img src="/logo.png" alt="image"/>*/}
            {/*Home*/}
            {((appType === "SRA") || (appType === 'SRAMandiri')) &&
              <img src="/logo_sra_baru-nobg-120.png" alt="logo" className="imglogo"/>
            }
            {((appType === "PRAP") || (appType === 'PRAPMandiri')) &&
              <img src="/logoprap-removebg-120.png" alt="logo" className="imglogo"/>
            }
            {((appType === "PISA")) &&
                <img src="/Logo-PISA-01.png" alt="logo" className="imglogo"/>
            }
            {((appType !== "SRA") && (appType !== 'SRAMandiri') && (appType !== "PRAP") && (appType !== 'PRAPMandiri') && (appType !== 'PISA')) &&
              <img src="/logo_sra_baru-nobg-120.png" alt="logo" className="imglogo"/>
            }
            {/*<img*/}
            {/*  src="/logo_sra_baru-nobg-120.png"*/}
            {/*  className="imglogo"*/}
            {/*  alt="logo kpppa"*/}
            {/*/>*/}
          </Link>
        </div>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
          <MenuItem>
            {(role === "administrator") &&
              <Link to="/admin">
                <Typography variant="body1" className="textlink">Admin</Typography>
              </Link>
            }
          </MenuItem>
          {/*<MenuItem>*/}
          {/*  <Link to="/">*/}
          {/*    <h1 className="textlink">Data</h1>*/}
          {/*  </Link>*/}
          {/*</MenuItem>*/}
          <MenuItem>
            <Link to="/changepassword">
              <Typography variant="body1" className="textlink">Ganti Password</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">
              <Typography variant="body1" className="textlink">Tutorial</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">
              <Typography variant="body1" className="textlink">Kontak</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">
              <Typography variant="body1" onClick={signOut} className="textlink">Logout</Typography>
              {/*<h1 className="textlink" onClick={signOut}>Logout</h1>*/}
            </Link>
          </MenuItem>
        </Box>
      </nav>
    </div>
  );
}
